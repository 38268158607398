.page {
    border: 1px solid gainsboro;
    border-radius: 10px;
     
    /* padding: 2rem; */
    overflow: hidden;
    max-width: 1250px;
    margin: 2rem auto;
    display: flex;
    -webkit-box-shadow: -1px 2px 16px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 2px 16px -5px rgba(0,0,0,0.75);
    box-shadow: -1px 2px 16px -5px rgba(0,0,0,0.75);
  }
  
  .page_right,
  .page_left {
    flex: 1;
  }

  .page_left{
    border-right: 2px solid gainsboro;
  }
  .header_pink {
    color: #fff;
    font-size: .6rem;
    padding-left: 2rem 20.5rem;
    background: #e71950;
    padding: 0.5rem 1rem;
  }
  
  ._details {
    font-size: .7rem;
    padding: 1rem 0;
  }

  ._details p{
    margin-left: 1rem;
    margin-bottom: .3rem;
  }
  
  ._detailsBold {
    font-weight: 600;
    color: gray;
    padding: 1rem;
    font-size: .7rem;
    background: #fafafa;
  }

  .smalltext{
    font-size: .5rem !important;
  }

  .signAture{
    display: flex;
    justify-content: flex-end;
    width: 85%;
    margin: 1rem 0;
  }
