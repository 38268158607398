.card
    min-width: 200px
    background: transparent !important
    border: none !important
    padding : 4px
    p
        text-align: justify
.card_child
    display: flex
    align-items: flex-start
    justify-content: flex-start

.spans 
 font-weight: light
 font-size: 12px