.homepage
    height: 100vh
    margin-top: 70px
    width: 75%
    margin: 70px auto

.autocomplete
    width: 100%
    margin: auto
    padding-top: 20px

    p:first-child
        font-size: 20px
        font-weight: 700
    
.selected-view
    font-size: 18px
    font-weight: 600
    width: 100%
    margin: auto
    padding-top: 20px

    display: flex
    justify-content: space-between
    align-items: center

    p
        margin-bottom: 0

.filters 
    display: grid
    grid-template-columns: repeat(4, 1fr)
    gap: 10px

    width: 100%
    margin: auto
    padding-top: 20px

    .filter-btns
        display: flex
        gap: 5px

.tableContainer
    width: 100% !important
    margin: 20px auto

.tableHead
    background-color: #7E90BC

    .tableRow
        .tableCell
            background-color: #7E90BC

    .tableCell
        color: white

.tableBody
    .tableRow:nth-child(odd)
        background-color: #E6F4F1
    .tableRow:nth-child(even)
        background-color: #cdeae4