.header {
    text-align: end;
    background-color: #dad7d757;
    height: 8vh;
    border-radius: 10px;
  }
  
  .body {
    margin-top: 15px;
    /* box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7); */
    background-color: #dad7d757;
    border-radius: 10px;
    height: 40vh;
  }

  .bodyLearn {
    margin-top: 15px;
    /* box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7); */
    background-color: #dad7d757;
    border-radius: 10px;
    height: 655px;
  }
  .bodyLearnShow {
    margin-top: 15px;
    /* box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.7); */
    /* background-color: #dad7d757; */
    border-radius: 10px;
    /* height: 100vh; */
    width: 100%;
  }

  .cardsAnalytics {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }

  .startPageDiv {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }

  .answerSheetDiv {
    /* display: flex; */
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    /* justify-content: flex-start; */
    font-family: monospace;
    padding: 40px 15px 0px 15px;
  }
  
  .cardsAnalytics > div {
    background-color: white;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 15%;
    /* margin: 0px 0px 30px 0px; */
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .startPageDiv > div {
    background-color: white;
    box-shadow: 0 8px 10px 0px rgba(16, 16, 17, 0.5);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 15%;
    /* margin: 0px 0px 30px 0px; */
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .answerSheetDiv > div {
    display: flex;
    align-items: right;
    flex-direction: column;
    padding: 15px;
  }

  .cardsAnalytics > .MuiButton-label {
    color: gray !important;
  }

  .startPageDiv > .MuiButton-label {
    color: gray !important;
  }

  .answerSheetDiv > .MuiButton-label {
    color: gray !important;
  }

  .slide > .MuiSlider-root.Mui-disabled {
    color: #52af77 !important;
  }